import React from "react";
import style from "./Youtube.module.scss";

function Youtube() {
  const arrayVideos = [
    {
      url: "https://www.youtube.com/embed/lTYaUc3A4Ls?si=tHzv9DyxNf4nfOPS",
      titulo: "Como tener CHAT GPT en tu celular | ChatGPT",
    },
    {
      url: "https://www.youtube.com/embed//Iq3R-1yKb90?si=mx2ilGlfA2DqBRhO",
      titulo: "TECNOLOGIAS para hacer una APLICACION sencilla",
    },
    {
      url: "https://www.youtube.com/embed/huJJzgVKpmw?si=nJLLfSCTu1q7xwFy",
      titulo: "Notificaciones PopUp en tu Aplicacion | Sonner ",
    },
    {
      url: "https://www.youtube.com/embed/WZDj2KIsWv0?si=CgyzeWofBhfh6S5B",
      titulo: "HERRAMIENTA para hacer BOCETOS online | Excalidraw ",
    },
  ];

  return (
    <div className={style.main}>
      <div className={style.container}>
        <img
          src="https://cdn.prod.website-files.com/6060e6c66acd5a7636de522c/64d68edb50a70ee26eebc10e_yt_logo_mono_light.png"
          alt=""
        />

        <h3>¡Aprende y crece conmigo en mis proyectos!</h3>

        <div className={style.videos}>
          {arrayVideos.map((item) => (
            <div className={style.video__item}>
              <iframe
                src={`${item.url}?controls=0&modestbranding=1&showinfo=0&rel=0&playsinline=1`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>

              <p>{item.titulo}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Youtube;
