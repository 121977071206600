import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import Video from "../../componentes/video/Video";
import style from "./links.module.scss";
import ContextGeneral from "../../servicios/contexto";

import { useNavigate, useLocation } from "react-router-dom";

import {
  AiFillLinkedin,
  AiOutlineInstagram,
  AiOutlineYoutube,
} from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";
import { IoMdRestaurant } from "react-icons/io";
import { FaRegUserCircle } from "react-icons/fa";
import { GrCatalog } from "react-icons/gr";
function Links() {
  const context = useContext(ContextGeneral);
  const navigate = useNavigate();
  // const fetchDatos = async () => {
  //   const fetchYoutube = await fetch(
  //     "https://www.googleapis.com/youtube/v3/search?key=AIzaSyB4BrehJtk5UpVZpFK8aXl4ve2A8sIo6rA&channelId=UCjnn1S2FKvvkicwEuoBlmAQ&part=snippet,id&order=date&maxResults=20&type=video"
  //   );
  //   const datos = await fetchYoutube.json();
  //   setVideos(datos.items);
  //   setLoader(true);
  // };

  const links = [
    {
      titulo: "Mi Web",
      url: "https://www.sebassotelo.com.ar/",
      icon: <FaRegUserCircle />,
    },
    {
      titulo: "App para Restaurantes",
      url: "https://gogo.com.ar/",
      icon: <IoMdRestaurant />,
    },
    {
      titulo: "Catalogos Online",
      url: "https://www.myshop.com.ar/",
      icon: <GrCatalog />,
    },
    {
      titulo: "YouTube",
      url: "https://www.youtube.com/@SebassDev",
      icon: <AiOutlineYoutube />,
    },
    {
      titulo: "Instagram",
      url: "https://www.instagram.com/_sebasdev/",
      icon: <AiOutlineInstagram />,
    },
    {
      titulo: "TikTok",
      url: "https://www.tiktok.com/@sebassdev",
      icon: <FaTiktok />,
    },
    {
      titulo: "Linkedin",
      url: "https://www.linkedin.com/in/sebassotelo/",
      icon: <AiFillLinkedin />,
    },
  ];

  useEffect(() => {
    window.scroll(0, 0);
    document.title =
      "Links | Sebas Sotelo | Desarrollo de soluciones digitales";
  }, []);

  return (
    <div className={style.links__container}>
      <div className={style.links__items}>
        <img
          onClick={() => navigate("/")}
          src="https://i.imgur.com/r3XyYLS.jpg"
          alt=""
        />
        {links.map((item) => (
          <>
            {item.url == "https://www.sebassotelo.com.ar/" ? (
              <p className={style.item} onClick={() => navigate("/")}>
                <span className={style.icon}>{item.icon}</span>{" "}
                <span>{item.titulo}</span>
              </p>
            ) : (
              <a className={style.item} href={item.url} target="_blank">
                <span className={style.icon}>{item.icon}</span>{" "}
                <span>{item.titulo}</span>
              </a>
            )}
          </>
        ))}
      </div>
    </div>
  );
}

export default Links;
